import useDisplayDuration from './hooks/useDisplayDuration';
import { sentence } from './text';

import type { Integration, IntegrationCapabilities, IntegrationModel, PsaType } from '@models/Integration';
import type { TeamType } from '@models/Team';
import type { DurationUnit } from '@models/AppointmentType';
import { DateTime } from '@shared/DateTime';

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
}

export function isWithinElemenet(point: { x: number; y: number }, container: HTMLElement): boolean {
  const rect = container.getBoundingClientRect();
  const top = rect.top + window.scrollY;
  const bottom = rect.bottom + window.scrollY;
  const left = rect.left + window.scrollX;
  const right = rect.right + window.scrollX;

  return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom;
}

export function ensureError(error: Error | string): Error {
  // Duck type way to check if error is Error instance
  if (error['message'] && error['stack']) return error as Error;

  return new Error(error.toString());
}

export function videoProvider(videoCallType: string | null): string {
  switch (videoCallType) {
    case 'teams':
      return 'Teams';
    case 'zoom':
      return 'Zoom';
    default:
      console.error(`Video Provider: ${videoCallType} is not supported`);
      return '';
  }
}

export function getCSRFToken(): string {
  return document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content || '';
}

export function displayBusinessDuration(duration: number, durationUnit: DurationUnit): string {
  const displayDuration = useDisplayDuration();

  switch (durationUnit) {
    case 'business_days':
      return duration === 1 ? '1 business day' : `${duration} business days`;
    case 'business_minutes':
      return displayDuration(duration).replace(' ', ' business ');
    default:
      return displayDuration(duration);
  }
}

export async function findAsync<T>(array: T[], predicate: (t: T) => Promise<boolean>): Promise<T | undefined> {
  try {
    for (const t of array) {
      if (await predicate(t)) return t;
    }
  } catch {
    return undefined;
  }
}

export function only(fn: () => any): (event: React.MouseEvent) => void {
  return function (event: React.MouseEvent): void {
    event.preventDefault();
    fn();
  };
}

export function sortBy<T>(array: T[], property: keyof T): T[] {
  return array.slice().sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;

    return 0;
  });
}

export function psaType(appointmentType: string): string {
  switch (appointmentType) {
    case 'ConnectWise::ScheduleEntry':
      return 'connect_wise';
    case 'Autotask::Appointment':
      return 'autotask';
    case 'Autotask::CompanyToDo':
      return 'autotask';
    case 'Autotask::ServiceCall':
      return 'autotask';
    case 'Autotask::TimeOffRequest':
      return 'autotask';
    case 'HaloPsa::Appointment':
      return 'halo_psa';
    case 'MicrosoftGraph::Event':
      return 'office_365';
    case 'BambooHR::TimeOffRequest':
      return 'bamboo_hr';
    default:
      return '';
  }
}

export function psaName(psaType: PsaType): string {
  switch (psaType) {
    case 'connect_wise':
      return 'ConnectWise PSA';
    case 'autotask':
      return 'Autotask';
    case 'office_365':
      return 'Microsoft 365';
    case 'halo_psa':
      return 'HaloPSA';
    case 'service_now':
      return 'ServiceNow';
  }
}

export function psaTeamName(psaTeamType: TeamType): string {
  switch (psaTeamType) {
    case 'service':
      return 'Service Team';
    case 'halo_psa':
      return 'HaloPSA Team';
    case 'project':
      return 'Project Team';
    case 'timezest':
      return 'TimeZest-Defined Team';
  }
}

export function teamMembers(members: string[]): string {
  switch (members.length) {
    case 0:
      return 'This team currently has no members.';
    case 1:
      return `${members[0]} only.`;
    case 2:
      return sentence(members);
    case 3:
      return sentence(members);
    default:
      return `${members[0]}, ${members[1]} and ${members.length - 2} other members.`;
  }
}

export function hasIntegration(integrations: IntegrationModel[], type: Integration): boolean {
  return integrations.some(i => i.type === type);
}

export function hasIntegrationCapability(
  integrations: IntegrationModel[],
  capability: IntegrationCapabilities
): boolean {
  return integrations.some(i => i.capabilities.includes(capability));
}

export function hasCapabilityFromIntegration(
  integrations: IntegrationModel[],
  capability: IntegrationCapabilities
): string {
  const integration = integrations.find(integration => integration.capabilities.includes(capability));

  return integration ? integration.name : '';
}

export function refreshCurrentPage(milliseconds: number) {
  window.setTimeout(() => {
    window.location.href = window.location.href + String();
  }, milliseconds);
}

export function displayTime(time: string, timezone: string): string {
  return new DateTime(timezone, time).formattedDateTime();
}
