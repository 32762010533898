import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';
import Badge from '@/ui/Badge';
import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';

import { pluralize } from '@shared/text';
import type Plan from '@models/Plan';

const PLAN_IDS = ['free-usd-oct-2020-1type', 'free-usd-apr-2020'];

interface Props {
  chargebeePlanId: string;
  eligibleFreePlanChange: boolean;
  freePlan: Plan;
  selectedPlan: string;
  handleSelectPlan: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FreePlanChoice: FC<Props> = ({ chargebeePlanId, eligibleFreePlanChange, freePlan, selectedPlan, handleSelectPlan }) => {
  const { status } = useContext(AccountContext);

  const selected = PLAN_IDS.includes(selectedPlan);
  const currenPlan = PLAN_IDS.includes(chargebeePlanId);

  return (
    <>
      {status === 'trial' ? (
        <RadioButtonOption
          appendedElement={
            <div className="text-muted small flex-grow-1">
              You can sign up to TimeZest Free once your trial has expired. Until then, you can use all of
              TimeZest&apos;s powerful features for free.
            </div>
          }
          label={
            <>
              <strong>TimeZest Free</strong> &mdash; Free
            </>
          }
          checked={false}
          disabled={true}
          name="chargebee_plan_id"
          value="free-plan"
          onChange={() => {}}
        />
      ) : (
        <RadioButtonOption
          checked={selected}
          disabled={!eligibleFreePlanChange}
          name="chargebee_plan_id"
          value="free-usd-oct-2020-1type"
          appendedElement={
            <>
              {eligibleFreePlanChange ? (
                <div className="text-muted small flex-grow-1">
                  Basic scheduling functionality, including branding, but at the best price of all: free.
                </div>
              ) : (
                <div className="text-danger small flex-grow-1">{freePlanDescription(freePlan)}</div>
              )}
            </>
          }
          label={
            <label htmlFor="chargebee_plan_id_free-usd-oct-2020-1type" className="form-check-label">
              <strong>TimeZest Free</strong> — Free
              {currenPlan && (
                <span>
                  &nbsp;
                  <Badge color="green">Your current plan!</Badge>
                </span>
              )}
            </label>
          }
          onChange={handleSelectPlan}
        />
      )}
    </>
  );
};

function freePlanDescription(plan: Plan) {
  if(plan.maximumAppointmentTypes === -1 && plan.maximumUsers === -1) return;

  if(plan.maximumAppointmentTypes >= 0 && plan.maximumUsers >= 0) {
    return `
      TimeZest Free only permits ${plan.maximumAppointmentTypes} ${pluralize('appointment type', plan.maximumAppointmentTypes)} and
      ${plan.maximumUsers} ${pluralize('user', plan.maximumUsers)}. To switch to this plan, first delete all but
      ${plan.maximumAppointmentTypes} of your appointment types and deactivate all but ${plan.maximumUsers} of your users.
    `;
  }

  if (plan.maximumAppointmentTypes >= 0) {
    return `
      TimeZest Free only permits ${plan.maximumAppointmentTypes} ${pluralize('appointment type', plan.maximumAppointmentTypes)}.
      To switch to this plan, first delete all but ${plan.maximumAppointmentTypes} of your appointment types.
    `;
  }

  if (plan.maximumUsers >= 0) {
    return `
      TimeZest Free only permits ${plan.maximumUsers} ${pluralize('user', plan.maximumUsers)}.
      To switch to this plan, first deactivate all but ${plan.maximumUsers} of your users.
    `;
  }
}

export default FreePlanChoice;
