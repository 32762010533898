import React, { Component } from 'react';

import AccountContext from './AccountContext';
import CurrentUserContext from './CurrentUserContext';
import FeatureFlagContext, { type FeatureFlagContextType } from './FeatureFlagContext';
import FlashContext from './FlashContext';
import PlanContext from './PlanContext';
import ThemeProvider from './ThemeProvider';

import { GqlProvider } from '@shared/apollo';
import type Plan from '@models/Plan';
import type { Account } from '@models/Account';
import type User from '@models/User';

interface ScreenProps<T> {
  props: T;
  flash: [[string, string]] | [];
  plan: Plan;
  featureFlags: FeatureFlagContextType;
  account: Account;
  user: User;
}

export default function asScreen<T>(Klass: React.ComponentType<any>) {
  return class extends Component<ScreenProps<T>> {
    render() {
      return (
        <AccountContext.Provider value={this.props.account}>
          <PlanContext.Provider value={this.props.plan}>
            <FeatureFlagContext.Provider value={this.props.featureFlags}>
              <FlashContext.Provider value={this.props.flash}>
                <CurrentUserContext.Provider value={this.props.user}>
                  <ThemeProvider>
                    <GqlProvider>
                      <Klass {...this.props.props} />
                    </GqlProvider>
                  </ThemeProvider>
                </CurrentUserContext.Provider>
              </FlashContext.Provider>
            </FeatureFlagContext.Provider>
          </PlanContext.Provider>
        </AccountContext.Provider>
      );
    }
  };
}
