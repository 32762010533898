import { useContext, type FC } from 'react';

import Row from '@/ui/Row';
import PlanContext from '@/PlanContext';
import UpdateCard from '@/UpdateCard';

import Plans from '../Plan';

import CardType from './CardType';

import type { BillingModel } from '@models/Billing';
import { formatDateLong, unixTimestampToDateString } from '@shared/text';

interface Props {
  billing: BillingModel;
}

const ActiveAccount: FC<Props> = ({ billing }) => {
  const { name } = useContext(PlanContext);

  const {
    planAfterRenewalName,
    subscriptionCancellationDate,
    subscriptionRenewsAt,
    eligibleFreePlanChange,
    freePlan,
    paymentAccount,
    paymentCard,
    nextBillingAt,
    amountDue,
    chargebeePlanId,
    chargebeeSite,
  } = billing;

  return (
    <>
      <Row
        label="Subscription Status"
        withInput={false}
        helpText={
          <>
            Invoices and other billing details can be accessed in our{' '}
            <a target="_blank" rel="noopener nofollow" data-method="post" href="/chargebee/portal_authentication">
              billing portal
            </a>
            .
          </>
        }
      >
        {planAfterRenewalName ? (
          <>
            <p className="m-0">
              You are subscribed to the <strong>{name}</strong> plan until {formatDateLong(subscriptionRenewsAt)}
            </p>
            <p className="text-muted m-0">
              After {formatDateLong(subscriptionRenewsAt)} you will be subscribed to the{' '}
              <strong>{planAfterRenewalName}</strong> plan.
            </p>
          </>
        ) : (
          <>
            You are subscribed to the <strong>{name}</strong> plan.
          </>
        )}
      </Row>

      <Row label="Next Payment" withInput={false}>
        {subscriptionCancellationDate ? (
          <>
            Your account is scheduled for cancellation on{' '}
            <strong>{formatDateLong(unixTimestampToDateString(subscriptionCancellationDate))}</strong>.
          </>
        ) : (
          <>
            The next payment of{' '}
            <strong>
              US{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountDue)}
            </strong>{' '}
            will be taken on <strong>{formatDateLong(unixTimestampToDateString(nextBillingAt))}</strong>.
          </>
        )}
      </Row>

      {paymentCard && (
        <Row label="Payment Card" withInput={false}>
          <span className="card-icon">
            <CardType cardType={paymentCard.cardType} last4={paymentCard.last4} />
            &nbsp;—&nbsp;
            <UpdateCard site={chargebeeSite} />
          </span>
        </Row>
      )}

      {paymentAccount && (
        <Row label="Payment Account" withInput={false}>
          <span className="card-icon">
            Your TimeZest subscription will be paid from your account at <strong>{paymentAccount.bankName}</strong>
            with last 4 digits
            <strong>{paymentAccount.last4}</strong>
          </span>
        </Row>
      )}

      <Row label="Change Plan" width={6} withInput={false}>
        <Plans
          chargebeePlanId={chargebeePlanId}
          eligibleFreePlanChange={eligibleFreePlanChange}
          freePlan={freePlan}
          pricingModel="tiered"
        />
      </Row>
    </>
  );
};

export default ActiveAccount;
