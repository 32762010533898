import type { FC } from 'react';

import Row from '@/ui/Row';

import Plans from '../Plan';

import type { BillingModel } from '@models/Billing';
import { distanceOfTimeFromNow } from '@shared/text';

interface Props {
  billing: BillingModel;
}

const TrialAccount: FC<Props> = ({ billing }) => {
  const { eligibleFreePlanChange, freePlan, chargebeePlanId, trialEndsAt } = billing;

  return (
    <>
      <Row withInput={false} label="Trial Status">
        <div>Your trial of TimeZest will expire {distanceOfTimeFromNow(trialEndsAt)}.</div>
      </Row>

      <Row label="Upgrade Account" width={6} withInput={false}>
        <Plans
          eligibleFreePlanChange={eligibleFreePlanChange}
          freePlan={freePlan}
          pricingModel="tiered"
          chargebeePlanId={chargebeePlanId}
        />
      </Row>
    </>
  );
};

export default TrialAccount;
